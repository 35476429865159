import {
    CREATE_NOTE_STARTED,
    CREATE_NOTE_COMPLETED,
    CREATE_NOTE_FAILED,  
    UPDATE_NOTE_STARTED,
    UPDATE_NOTE_COMPLETED,
    UPDATE_NOTE_FAILED,   
    GETBYID_NOTE_STARTED,
    GETBYID_NOTE_COMPLETED,
    GETBYID_NOTE_FAILED,   
    GET_NOTE_STARTED,
    GET_NOTE_COMPLETED,
    GET_NOTE_FAILED
} from '../actions/Notes';

const intialLoginData = {
    status: {
        statusCode: 300,
        statusDisplay: "",
        statusValue: true
    },
    items: [],
    loginInput: {
        userId: "",
        password: "",
        username: "",
        jwtToken: "",
        secretKey: "",
        securityQuestion: "",
        email: "",
        otp: "",
        newPassword: "",
        confirmNewPass: "",
        answer: "",
        roleFkId: { roleId: 0, roleName: "", description: "", status: 0, createdAt: "", createdBy: "", updatedAt: "", updatedBy: "" },
        status: 0,
        createdAt: "",
        createdBy: "",
        updatedAt: "",
        updatedBy: ""

    },
    isLoading: true,
    isFormSubmit: false,
    isLoggedIn: false,
    loggedStatus: false,
    error: ''
}

export const createNoteData = (state = intialLoginData, action: any) => {
    switch (action.type) {

        case CREATE_NOTE_STARTED:
            return {
                ...state,
                isLoading: true,
                isFormSubmit: true,
                items: [],
                loginInput: action.input

            };

        case CREATE_NOTE_COMPLETED:
            return {
                ...state,
                isLoading: false,
                isFormSubmit: true,
                status: action.status,
                items: action.payload,
                loggedStatus: true
            };

        case CREATE_NOTE_FAILED:
            return {
                ...state,
                isLoading: true,
                loggedStatus: false
            };

        default:
            return state;
    }

};

export const updateNoteData = (state = intialLoginData, action: any) => {
    switch (action.type) {

        case UPDATE_NOTE_STARTED:
            return {
                ...state,
                isLoading: true,
                isFormSubmit: true,
                items: [],
                loginInput: action.input

            };

        case UPDATE_NOTE_COMPLETED:
            return {
                ...state,
                isLoading: false,
                isFormSubmit: true,
                status: action.status,
                items: action.payload,
                loggedStatus: true
            };

        case UPDATE_NOTE_FAILED:
            return {
                ...state,
                isLoading: true,
                loggedStatus: false
            };

        default:
            return state;
    }

};

export const getByIdNoteData = (state = intialLoginData, action: any) => {
    switch (action.type) {

        case GETBYID_NOTE_STARTED:
            return {
                ...state,
                isLoading: true,
                isFormSubmit: true,
                items: [],
                loginInput: action.input

            };

        case GETBYID_NOTE_COMPLETED:
            return {
                ...state,
                isLoading: false,
                isFormSubmit: true,
                status: action.status,
                items: action.payload,
                loggedStatus: true
            };

        case GETBYID_NOTE_FAILED:
            return {
                ...state,
                isLoading: true,
                loggedStatus: false
            };

        default:
            return state;
    }

};

export const getAllNoteData = (state = intialLoginData, action: any) => {
    switch (action.type) {

        case GET_NOTE_STARTED:
            return {
                ...state,
                isLoading: true,
                isFormSubmit: true,
                items: [],
                loginInput: action.input

            };

        case GET_NOTE_COMPLETED:
            return {
                ...state,
                isLoading: false,
                isFormSubmit: true,
                status: action.status,
                items: action.payload,
                loggedStatus: true
            };

        case GET_NOTE_FAILED:
            return {
                ...state,
                isLoading: true,
                loggedStatus: false
            };

        default:
            return state;
    }

};