
import { HttpLogin } from "../Http";

export class createFormAPI {
  public static createForms(org: any) {
    var url = "/api/forms/add"
    var obj = JSON.stringify(org);

    const resultMethod = HttpLogin.axios().post(url, obj, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*"
      }
    })
      .then(res => {


        console.log("API Response in post " + JSON.stringify(res.data));
        return res;
      })
      .catch((e: any) => {
        console.log("Error in post " + JSON.stringify(e));
        return e;
      });
    return resultMethod;
  }

}

export class GetAllFormsAPI {
  public static getAllForms(org: any) {
    var url = "/api/forms/get"

    const resultMethod = HttpLogin.axios().get(url, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*"
      }
    })
      .then(res => {


        //  console.log("API Response in post " +JSON.stringify(res.data));
        return res;
      })
      .catch((e: any) => {
        //  console.log("Error in post " +JSON.stringify(e));
        return e;
      });
    return resultMethod;
  }

}

export class GetByNameFormsAPI {
  public static getByNameForms(org: any) {
    var url = "/api/forms/getByName/" + org;
    // console.log(JSON.stringify(org));
    const resultMethod = HttpLogin.axios().get(url, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*"
      }
    })
      .then(res => {

        return res;
      })
      .catch((e: any) => {
        //  console.log("Error in post " +JSON.stringify(e));
        return e;
      });
    return resultMethod;
  }

}


export class GetAllFieldsAPI {
  public static getAllFields(org: any) {
    // console.log(JSON.stringify(org));
    var url = "/api/field/get";
    // var obj = JSON.stringify(org);
    // console.log(JSON.stringify(org));
    const resultMethod = HttpLogin.axios().get(url, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*"
      }
    })
      .then(res => {

        return res;
      })
      .catch((e: any) => {
        //  console.log("Error in post " +JSON.stringify(e));
        return e;
      });
    return resultMethod;
  }

}

export class CreateFieldsAPI {
  public static createFields(org: any) {
    var url = "/api/field/add"
    var obj = JSON.stringify(org);

    const resultMethod = HttpLogin.axios().post(url, obj, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*"
      }
    })
      .then(res => {


        console.log("API Response in post " + JSON.stringify(res.data));
        return res;
      })
      .catch((e: any) => {
        // console.log("Error in post " +JSON.stringify(e));
        return e;
      });
    return resultMethod;
  }

}

export class UpdateFieldsAPI {
  public static UpdateFields(org: any) {
    var url = "/api/forms/update/" + org.id
    var obj = JSON.stringify(org);
    // console.log("Input = " +JSON.stringify(obj));
    const resultMethod = HttpLogin.axios().put(url, obj, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*"
      }
    })
      .then(res => {

        // console.log("API Response in post " +JSON.stringify(res.data));
        return res;
      })
      .catch((e: any) => {
        //  console.log("Error in post " +JSON.stringify(e));
        return e;
      });
    return resultMethod;
  }

}

export class CreateFilledFormAPI {
  public static createFilledForm(org: any) {
    var url = "/api/filledForm/add"
    var obj = JSON.stringify(org);

    const resultMethod = HttpLogin.axios().post(url, obj, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*"
      }
    })
      .then(res => {


        console.log("API Response in post " + JSON.stringify(res.data));
        return res;
      })
      .catch((e: any) => {
        console.log("Error in post " + JSON.stringify(e));
        return e;
      });
    return resultMethod;
  }

}

export class GetFilledFormAPI {
  public static getByPatientIdFilledForm(org: any) {
    var url = "/api/filledForm/getByPid/" + org;
    // var obj = JSON.stringify(org);
    // console.log(JSON.stringify(org));
    const resultMethod = HttpLogin.axios().get(url, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*"
      }
    })
      .then(res => {

        return res;
      })
      .catch((e: any) => {
        //  console.log("Error in post " +JSON.stringify(e));
        return e;
      });
    return resultMethod;
  }

}

export class DeleteSelectedFieldAPI {
  public static deleteSelectedFields(org: any) {
    var url = "/api/field/ById/" + org;

    const resultMethod = HttpLogin.axios().delete(url, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*"
      }
    })
      .then(res => {

        // alert(res.data.message.description);
        // setTimeout(() => {
        //   window.location.reload();

        // }, (1500));
        console.log("API Response in post " + JSON.stringify(res.data));
        return res;
      })
      .catch((e: any) => {
        //  console.log("Error in post " +JSON.stringify(e));
        return e;
      });
    return resultMethod;
  }

}

export class DeleteFilledFormsAPI {
  public static deleteFilledForm(org: any, newOrg: any) {
    var url = "/api/filledForm/delete/" + org + "/" + newOrg;

    const resultMethod = HttpLogin.axios().delete(url, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*"
      }
    })
      .then(res => {


        //  console.log("API Response in post " +JSON.stringify(res.data));
        return res;
      })
      .catch((e: any) => {
        //  console.log("Error in post " +JSON.stringify(e));
        return e;
      });
    return resultMethod;
  }

}