export const CREATE_NURSE_STARTED = "CREATE_NURSE_STARTED";
export const CREATE_NURSE_COMPLETED = "CREATE_NURSE_COMPLETED";
export const CREATE_NURSE_FAILED = "CREATE_NURSE_FAILED";

export const UPDATE_NURSE_STARTED = "UPDATE_NURSE_STARTED";
export const UPDATE_NURSE_COMPLETED = "UPDATE_NURSE_COMPLETED";
export const UPDATE_NURSE_FAILED = "UPDATE_NURSE_FAILED";

export const GETALL_NURSE_STARTED = "GETALL_NURSE_STARTED";
export const GETALL_NURSE_COMPLETED = "GETALL_NURSE_COMPLETED";
export const GETALL_NURSE_FAILED = "GETALL_NURSE_FAILED";

export const GETBYID_NURSE_STARTED = "GETBYID_NURSE_STARTED";
export const GETBYID_NURSE_COMPLETED = "GETBYID_NURSE_COMPLETED";
export const GETBYID_NURSE_FAILED = "GETBYID_NURSE_FAILED";

export const GETBYADMITTINGNURSE_NURSE_STARTED = "GETBYADMITTINGNURSE_NURSE_STARTED";
export const GETBYADMITTINGNURSE_NURSE_COMPLETED = "GETBYADMITTINGNURSE_NURSE_COMPLETED";
export const GETBYADMITTINGNURSE_NURSE_FAILED = "GETBYADMITTINGNURSE_NURSE_FAILED";

export const DELETEBYID_NURSE_STARTED = "DELETEBYID_NURSE_STARTED";
export const DELETEBYID_NURSE_COMPLETED = "DELETEBYID_NURSE_COMPLETED";
export const DELETEBYID_NURSE_FAILED = "DELETEBYID_NURSE_FAILED";

export const GENERATEPDF_NURSE_STARTED = "GENERATEPDF_NURSE_STARTED";
export const GENERATEPDF_NURSE_COMPLETED = "GENERATEPDF_NURSE_COMPLETED";
export const GENERATEPDF_NURSE_FAILED = "GENERATEPDF_NURSE_FAILED";

export interface ILogin {
    status: {
      statusCode: number;
      statusDisplay: string;
      statusValue: boolean;
    };
    items: Array<any>;
    loginInput: {
      userId: string;
      username:string,
      password: string;
      organization:string;
      jwtToken: string;
      secretKey: string,
      email:string,
      otp:string,
      confirmNewPass: string,
      newPassword:string,
      securityQuestion: string;
      answer: string;
      roleFkId: {
        roleId: number;
        roleName: string;
        description: string;
        status: number;
        createdAt: Date;
        createdBy: string;
        updatedAt: Date;
        updatedBy: string;
      };
      status: number;
      createdAt: Date;
      createdBy: string;
      updatedAt: Date;
      updatedBy: string;
    };
    loginID: number;
    isLoading: boolean;
    error: string;
  }

  export const createNurse = (loginInput: any) => {    
    return {
      type: CREATE_NURSE_STARTED,
      payload: "status",
      input: loginInput
    };
  };

  export const updateNurse = (loginInput: any) => {    
    return {
      type: UPDATE_NURSE_STARTED,
      payload: "status",
      input: loginInput
    };
  };

  export const getAll = () => {    
    return {
      type: GETALL_NURSE_STARTED,
      payload: "status"
    };
  };

  export const getById = (loginInput: any) => {    
    return {
      type: GETBYID_NURSE_STARTED,
      payload: "status",
      input: loginInput
    };
  };

  export const getByAdmittingNurse = (loginInput: any) => {    
    return {
      type: GETBYADMITTINGNURSE_NURSE_STARTED,
      payload: "status",
      input: loginInput
    };
  };

  export const deleteById = (loginInput: any) => {    
    return {
      type: DELETEBYID_NURSE_STARTED,
      payload: "status",
      input: loginInput
    };
  };

  export const generatePdf = (loginInput: any) => {    
    return {
      type: GENERATEPDF_NURSE_STARTED,
      payload: "status",
      input: loginInput
    };
  };