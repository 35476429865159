export const CREATE_NOTE_STARTED = "CREATE_NOTE_STARTED";
export const CREATE_NOTE_COMPLETED = "CREATE_NOTE_COMPLETED";
export const CREATE_NOTE_FAILED = "CREATE_NOTE_FAILED";

export const UPDATE_NOTE_STARTED = "UPDATE_NOTE_STARTED";
export const UPDATE_NOTE_COMPLETED = "UPDATE_NOTE_COMPLETED";
export const UPDATE_NOTE_FAILED = "UPDATE_NOTE_FAILED";

export const GETBYID_NOTE_STARTED = "GETBYID_NOTE_STARTED";
export const GETBYID_NOTE_COMPLETED = "GETBYID_NOTE_COMPLETED";
export const GETBYID_NOTE_FAILED = "GETBYID_NOTE_FAILED";

export const GET_NOTE_STARTED = "GET_NOTE_STARTED";
export const GET_NOTE_COMPLETED = "GET_NOTE_COMPLETED";
export const GET_NOTE_FAILED = "GET_NOTE_FAILED";

export const noteCreate = (noteCreateInput: any) => {
 
    return {
        type: CREATE_NOTE_STARTED,
        payload: 'status',
        input: noteCreateInput
    };
};

export const noteUpdate = (noteUpdateInput: any) => {
 
    return {
        type: UPDATE_NOTE_STARTED,
        payload: 'status',
        input: noteUpdateInput
    };
};

export const getNoteById = (id) => {
   
    return {
        type: GETBYID_NOTE_STARTED ,
        payload: 'Value',
        input:id 
    };
};

export const getAllNotes = (notes) => {
   
    return {
        type: GET_NOTE_STARTED ,
        payload: 'Value',
        input:notes 
    };
};