import axios, { AxiosInstance } from "axios";
import * as Constants from "../../src/containers/pages/Constants/ConstantValues";
let API_URL = "";
const iPAddress = Constants.IpAddress;
// const addIpAddress = Constants.AddIpAddress;
const fileUploadURL = Constants.fileUploadURL;
//loginapi  fileUploadApi
const ImplantIP = Constants.ImplantIP;
if (process.env.REACT_APP_API_URL) {

  API_URL = process.env.REACT_APP_API_URL;
}


export class HttpLogin {
  public static axios(): AxiosInstance {
    return axios.create({
      // baseURL: iPAddress+'/loginapi/'
      baseURL: iPAddress
    });
  }
}

export class HttpAddLogin {
  public static axios(): AxiosInstance {
    return axios.create({
      // baseURL: iPAddress+'/fileUploadApi/'
      baseURL: fileUploadURL
    });
  }
}

export class HttpMettlerHealth {
  public static axios(): AxiosInstance {
    return axios.create({
      baseURL: Constants.newIpAddress
    });
  }
}

export class HttpImplantDevice {
  public static axios(): AxiosInstance {
    return axios.create({
      baseURL: newImplantIp
    });
  }
}

export const apiURL = iPAddress + '/api2/api/';
export const caseURL = iPAddress + '/api3/';
export const caseInitiationURL = iPAddress + '/api4/';
export const caseUploadURL = iPAddress + '/api7/';
export const downloadURL = iPAddress + '/mettlerhealth/';
export const formTypeURL = iPAddress + '/api8/';
export const newImplantIp = ImplantIP + '/api/v2/devices/'
