import { HttpLogin } from "../Http";

export class SuicideAPI {
 
    public static saveSuicide(user: any) { 
      var url = "/api/suicide/register";   
      var obj = JSON.stringify(user);
   
      const resultMethod =   HttpLogin.axios().post(url, obj, {
            headers: {
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "*",
              "Access-Control-Allow-Headers": "*"
            }
          })
          .then(res => {         
            return res;   
          })
          .catch((e: any) => {
            return e;               
          });
          return resultMethod;
    }

    public static getAll(user: any) { 
        var url = "/api/suicide/getAll";   
       
        const resultMethod =   HttpLogin.axios().get(url,{
              headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
              }
            })
            .then(res => {         
              return res;   
            })
            .catch((e: any) => {
              return e;               
            });
            return resultMethod;
      }

      
    public static getBySuicideRisk(userInput: any) { 
        var url = "/api/suicide/BySuicideRisk/"+userInput;   
       
        const resultMethod =   HttpLogin.axios().get(url,{
              headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
              }
            })
            .then(res => {         
              return res;   
            })
            .catch((e: any) => {
              return e;               
            });
            return resultMethod;
      }

      public static getByPId(userInput: any) { 
        var url = "/api/suicide/ByPid/"+userInput;   
       
        const resultMethod =   HttpLogin.axios().get(url,{
              headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
              }
            })
            .then(res => {         
              return res;   
            })
            .catch((e: any) => {
              return e;               
            });
            return resultMethod;
      }

      public static getById(userInput: any) { 
        var url = "/api/suicide/ById/"+userInput;   
       
        const resultMethod =   HttpLogin.axios().get(url,{
              headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
              }
            })
            .then(res => {         
              return res;   
            })
            .catch((e: any) => {
              return e;               
            });
            return resultMethod;
      }

      public static deleteById(userInput: any) { 
        var url = "/api/suicide/deleteById/"+userInput;   
       
        const resultMethod =   HttpLogin.axios().delete(url,{
              headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
              }
            })
            .then(res => {         
              return res;   
            })
            .catch((e: any) => {
              return e;               
            });
            return resultMethod;
      }
}