import { put, call } from "redux-saga/effects";
import {
    CREATE_NOTE_COMPLETED,
    CREATE_NOTE_FAILED,  
    UPDATE_NOTE_COMPLETED,
    UPDATE_NOTE_FAILED,   
    GETBYID_NOTE_COMPLETED,
    GETBYID_NOTE_FAILED,   
    GET_NOTE_COMPLETED,
    GET_NOTE_FAILED
} from "../actions/Notes";
import {CreateNotesAPI,UpdateNotesAPI,GetAllNotesAPI,GetByIdNotesAPI } from "../../utils/api/NotesAPI";

export function* createNoteData(request: any) {
    try {
        const result = yield call(CreateNotesAPI.createNotes, request.input);
        yield put({
            type: CREATE_NOTE_COMPLETED,
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: CREATE_NOTE_FAILED, payload: e });
        return e;

    }
}
export function* updateNoteData(request: any) {
    try {
        const result = yield call(UpdateNotesAPI.updateNotes, request.input);
        yield put({
            type: UPDATE_NOTE_COMPLETED,
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: UPDATE_NOTE_FAILED, payload: e });
        return e;

    }
}
export function* getByIdNoteData(request: any) {
    try {
        const result = yield call(GetByIdNotesAPI.getByIdNotesData, request.input);
        yield put({
            type: GETBYID_NOTE_COMPLETED,
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: GETBYID_NOTE_FAILED, payload: e });
        return e;

    }
}
export function* getAllNoteData(request: any) {
    try {
        const result = yield call(GetAllNotesAPI.getAllNotesData, request.input);
        yield put({
            type: GET_NOTE_COMPLETED,
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: GET_NOTE_FAILED, payload: e });
        return e;

    }
}