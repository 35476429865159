import { HttpLogin } from "../Http";
export class updateNurseAPI {
    public static updateById(org: any) {
        var url = "/api/NurseToNurse/updateById/" + org.id
        var obj = JSON.stringify(org);
        const resultMethod = HttpLogin.axios().put(url, obj, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => {
                return res;
            })
            .catch((e: any) => {

                return e;
            });
        return resultMethod;
    }
}
export class createNurseAPI {
    public static createNurse(org: any) {
        var url = "/api/NurseToNurse/register"
        var obj = JSON.stringify(org);
        const resultMethod = HttpLogin.axios().post(url, obj, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => {
                return res;
            })
            .catch((e: any) => {

                return e;
            });
        return resultMethod;
    }
}
export class getAllAPI {
    public static getAll(org: any) {
        var url = "/api/NurseToNurse/getAll"

        const resultMethod = HttpLogin.axios().get(url, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => {
                return res;
            })
            .catch((e: any) => {

                return e;
            });
        return resultMethod;
    }
}
export class getByIdAPI {
    public static getById(org: any) {
        var url = "/api/NurseToNurse/ById/"+org.id

        const resultMethod = HttpLogin.axios().get(url, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => {
                return res;
            })
            .catch((e: any) => {

                return e;
            });
        return resultMethod;
    }
}
export class deleteByIdAPI {
    public static deleteById(org: any) {
        var url = "/api/NurseToNurse/ById/"+org.id

        const resultMethod = HttpLogin.axios().delete(url, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => {
                return res;
            })
            .catch((e: any) => {

                return e;
            });
        return resultMethod;
    }
}
export class getByAdmittingNurseAPI {
    public static getByAdmittingNurse(org: any) {
        var url = "/api/NurseToNurse/ByAdmittingNurse/"+org.admittingNurse

        const resultMethod = HttpLogin.axios().get(url, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => {
                return res;
            })
            .catch((e: any) => {
                return e;
            });
        return resultMethod;
    }
}

export class generatePdfAPI {
    public static generatePdf(org: any) {
        var url = "/mettlerhealth/generateNursePdf"
        var obj = JSON.stringify(org);
        const resultMethod = HttpLogin.axios().post(url,obj,{
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => {
                return res;
            })
            .catch((e: any) => {
                return e;
            });
        return resultMethod;
    }
}