import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Box, Typography, IconButton, } from '@mui/material';
import mettlerTitle from '../../assets/images/mettler_images/MettlerTitle_dark.svg';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';

interface MenuItem {
    title: string;
    icon: JSX.Element;
    subItems?: string[];
    link: any
}

interface LeftPanelProps {
    menuItems: MenuItem[];
    toggle: any
}

const LeftPanel: React.FC<LeftPanelProps> = ({ menuItems, toggle }) => {

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                width: 280,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 280,
                    boxSizing: 'border-box',
                    bgcolor: 'black',
                    borderRight: '1px solid #e0e0e0',
                },
            }}
        >
            <Box sx={{ padding: '16px', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    <img src={mettlerTitle} alt="icon" />
                </Typography>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggle}>
                    <MenuIcon sx={{ color: "white" }} />
                </IconButton>
            </Box>
            <Box sx={{ padding: '16px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <IconButton
                    sx={{
                        backgroundColor: '#00cdffcc',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#01CDFF',
                        },
                        width: "60%",
                        borderRadius: "40px",
                        padding: '10px',
                        fontSize: "15px"
                    }}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => {
                        window.location.href = "/MettlerAddOrganization";
                    }}
                >
                    <AddIcon />
                    Create New
                </IconButton>
            </Box>
            <List>
                {menuItems.map((menuItem) => (
                    <React.Fragment key={menuItem.title}>
                        <ListItem button onClick={() => window.location.href = menuItem.link}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                                }
                            }}
                        >
                            <ListItemIcon sx={{ color: "white" }}>{menuItem.icon}</ListItemIcon>
                            <ListItemText sx={{ color: "white" }} primary={menuItem.title} />
                        </ListItem>
                    </React.Fragment>
                ))}
            </List>
        </Drawer>
    );
};

export default LeftPanel;
