import {
   CREATE_SUICIDE_COMPLETED,CREATE_SUICIDE_FAILED,GETALL_SUICIDE_COMPLETED,
    GETALL_SUICIDE_FAILED,GET_BYID_SUICIDE_COMPLETED,GET_BYID_SUICIDE_FAILED
    ,GET_BYPID_SUICIDE_COMPLETED,GET_BYPID_SUICIDE_FAILED,GET_BYSUICIDERISK_COMPLETED
    ,GET_BYSUICIDERISK_FAILED,DELETE_BYID_SUICIDE_COMPLETED,DELETE_BYID_SUICIDE_FAILED
}from '../actions/suicideRisk';
import { put, call } from "redux-saga/effects";
import { SuicideAPI } from "../../utils/api/suicideRiskAPI";

export function* CreateSuicideData(request: any) {
  
    try {
           const result=yield call(SuicideAPI.saveSuicide, request.input);
         yield put({ 
            type: CREATE_SUICIDE_COMPLETED,     
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: CREATE_SUICIDE_FAILED, payload: e.message });
    }
}

export function* getAllData(request: any) {
  
    try {
           const result=yield call(SuicideAPI.getAll, request.input);
         yield put({ 
            type: GETALL_SUICIDE_COMPLETED,     
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: GETALL_SUICIDE_FAILED, payload: e.message });
    }
}

export function* getById(request: any) {
  
    try {
           const result=yield call(SuicideAPI.getById, request.input);
         yield put({ 
            type: GET_BYID_SUICIDE_COMPLETED,     
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: GET_BYID_SUICIDE_FAILED, payload: e.message });
    }
}

export function* getByPId(request: any) {
  
    try {
           const result=yield call(SuicideAPI.getByPId, request.input);
         yield put({ 
            type: GET_BYPID_SUICIDE_COMPLETED,     
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: GET_BYPID_SUICIDE_FAILED, payload: e.message });
    }
}

export function* getBySuicideRisk(request: any) {
  
    try {
           const result=yield call(SuicideAPI.getBySuicideRisk, request.input);
         yield put({ 
            type: GET_BYSUICIDERISK_COMPLETED,     
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: GET_BYSUICIDERISK_FAILED, payload: e.message });
    }
}

export function* deleteById(request: any) {
  
    try {
           const result=yield call(SuicideAPI.deleteById, request.input);
         yield put({ 
            type: DELETE_BYID_SUICIDE_COMPLETED,     
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: DELETE_BYID_SUICIDE_FAILED, payload: e.message });
    }
}