import {
    GETALL_ASCENSION_STARTED,
    GETALL_ASCENSION_COMPLETED,
    GETALL_ASCENSION_FAILED,       
} from '../actions/AscensionFile';

const intialLoginData = {
    status: {
        statusCode: 300,
        statusDisplay: "",
        statusValue: true
    },
    items: [],
    loginInput: {       
        userId: "",
        password: "",
        username:"",
        jwtToken:"",
        secretKey:"",
        securityQuestion: "",
        email:"",
        otp:"",
        newPassword:"",  
        confirmNewPass: "",   
        answer: "",
        roleFkId:{roleId:0,roleName:"",description:"",status:0,createdAt:"",createdBy:"",updatedAt:"",updatedBy:""},
        status:0,
        createdAt:"",
        createdBy:"",
        updatedAt:"",
        updatedBy:""
    
    },
    isLoading: true,
    isFormSubmit: false,
    isLoggedIn: false,
    loggedStatus: false,
    error: ''
}



export const getAllAscensionData = (state = intialLoginData, action: any) => {
 
    switch (action.type) {
       
        case GETALL_ASCENSION_STARTED:
           return {
                ...state,
                isLoading: true,    
                isFormSubmit:true,
                items:[],
                loginInput: action.input
                
            };

        case GETALL_ASCENSION_COMPLETED:
              return {
                ...state,
                isLoading: false,
                isFormSubmit:true,
                status: action.status,
                items: action.payload,
                loggedStatus: true
                };
            
        case GETALL_ASCENSION_FAILED:
            return {
                ...state,
                isLoading: true,
                loggedStatus: false
            }; 
      
            default:
                return state;
        }
        
    };

   
   