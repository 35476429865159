export const CREATE_SUICIDE_STARTED = "CREATE_SUICIDE_STARTED";
export const CREATE_SUICIDE_COMPLETED = "CREATE_SUICIDE_COMPLETED";
export const CREATE_SUICIDE_FAILED = "CREATE_SUICIDE_FAILED";

export const GETALL_SUICIDE_STARTED ="GETALL_SUICIDE_STARTED";
export const GETALL_SUICIDE_COMPLETED ="GETALL_SUICIDE_COMPLETED";
export const GETALL_SUICIDE_FAILED ="GETALL_SUICIDE_FAILED";

export const GET_BYID_SUICIDE_STARTED ="GET_BYID_SUICIDE_STARTED";
export const GET_BYID_SUICIDE_COMPLETED ="GET_BYID_SUICIDE_COMPLETED";
export const GET_BYID_SUICIDE_FAILED ="GET_BYID_SUICIDE_FAILED";

export const GET_BYPID_SUICIDE_STARTED ="GET_BYPID_SUICIDE_STARTED";
export const GET_BYPID_SUICIDE_COMPLETED ="GET_BYPID_SUICIDE_COMPLETED";
export const GET_BYPID_SUICIDE_FAILED ="GET_BYPID_SUICIDE_FAILED";

export const GET_BYSUICIDERISK_STARTED ="GET_BYSUICIDERISK_STARTED";
export const GET_BYSUICIDERISK_COMPLETED ="GET_BYSUICIDERISK_COMPLETED";
export const GET_BYSUICIDERISK_FAILED ="GET_BYSUICIDERISK_FAILED";

export const DELETE_BYID_SUICIDE_STARTED ="DELETE_BYID_SUICIDE_STARTED";
export const DELETE_BYID_SUICIDE_COMPLETED ="DELETE_BYID_SUICIDE_COMPLETED";
export const DELETE_BYID_SUICIDE_FAILED ="DELETE_BYID_SUICIDE_FAILED"

export interface ILogin {
    status: {
      statusCode: number;
      statusDisplay: string;
      statusValue: boolean;
    };
    items: Array<any>;
    loginInput: {
      userId: string;
      username:string,
      password: string;
      organization:string;
      jwtToken: string;
      secretKey: string,
      email:string,
      otp:string,
      confirmNewPass: string,
      newPassword:string,
      securityQuestion: string;
      answer: string;
      roleFkId: {
        roleId: number;
        roleName: string;
        description: string;
        status: number;
        createdAt: Date;
        createdBy: string;
        updatedAt: Date;
        updatedBy: string;
      };
      status: number;
      createdAt: Date;
      createdBy: string;
      updatedAt: Date;
      updatedBy: string;
    };
    loginID: number;
    isLoading: boolean;
    error: string;
  }

  export const getById  = (loginInput: any) => { 
  
    return {
      type: GET_BYID_SUICIDE_STARTED,
      payload: "status",
      input: loginInput
    };
  };

  export const getByPId  = (loginInput: any) => { 
  
    return {
      type: GET_BYPID_SUICIDE_STARTED,
      payload: "status",
      input: loginInput
    };
  };

  export const getBySuicideRisk  = (loginInput: any) => { 
  
    return {
      type: GET_BYSUICIDERISK_STARTED,
      payload: "status",
      input: loginInput
    };
  };

  export const getAll  = (loginInput: any) => { 
  
    return {
      type: GETALL_SUICIDE_STARTED,
      payload: "status",
      input: loginInput
    };
  };

  export const deleteById  = (loginInput: any) => { 
  
    return {
      type: DELETE_BYID_SUICIDE_STARTED,
      payload: "status",
      input: loginInput
    };
  };

  export const createSuicide  = (loginInput: any) => { 
  
    return {
      type: CREATE_SUICIDE_STARTED,
      payload: "status",
      input: loginInput
    };
  };
