import {
    CREATE_SUICIDE_STARTED,CREATE_SUICIDE_COMPLETED,CREATE_SUICIDE_FAILED,GETALL_SUICIDE_STARTED,GETALL_SUICIDE_COMPLETED,
    GETALL_SUICIDE_FAILED,GET_BYID_SUICIDE_STARTED,GET_BYID_SUICIDE_COMPLETED,GET_BYID_SUICIDE_FAILED
    ,GET_BYPID_SUICIDE_STARTED,GET_BYPID_SUICIDE_COMPLETED,GET_BYPID_SUICIDE_FAILED,GET_BYSUICIDERISK_STARTED,GET_BYSUICIDERISK_COMPLETED
    ,GET_BYSUICIDERISK_FAILED,DELETE_BYID_SUICIDE_STARTED,DELETE_BYID_SUICIDE_COMPLETED,DELETE_BYID_SUICIDE_FAILED
}from '../actions/suicideRisk';

const intialData = {
    status: {
      statusCode: 300,
      statusDisplay: "",
      statusValue: true
    },
    items: [],
    userInput: {
      insert_datetime: "",
      inserted_by: "",
      update_datetime: "",
      updated_by: ""
  
    },
    isLoading: true,
    isFormSubmit: false,
    isLoggedIn: false,
    error: ''
  };

  export const createData = (state = intialData, action: any) => {

    switch (action.type) {
      case CREATE_SUICIDE_STARTED:
        return {
          ...state,
          items: [],
          isLoading: true,
        };
      case CREATE_SUICIDE_COMPLETED:
        return {
          ...state,
          isLoading: false,
          status: action.status,
          items: action.payload
        };
      case CREATE_SUICIDE_FAILED:
        return {
          ...state,
          isLoading: true,
        };
      default:
        return state;
    };
  };

  export const getAllFile = (state = intialData, action: any) => {

    switch (action.type) {
      case GETALL_SUICIDE_STARTED:
        return {
          ...state,
          items: [],
          isLoading: true,
        };
      case GETALL_SUICIDE_COMPLETED:
        return {
          ...state,
          isLoading: false,
          status: action.status,
          items: action.payload
        };
      case GETALL_SUICIDE_FAILED:
        return {
          ...state,
          isLoading: true,
        };
      default:
        return state;
    };
  };

  export const getSuicideById = (state = intialData, action: any) => {

    switch (action.type) {
      case GET_BYID_SUICIDE_STARTED:
        return {
          ...state,
          items: [],
          isLoading: true,
        };
      case GET_BYID_SUICIDE_COMPLETED:
        return {
          ...state,
          isLoading: false,
          status: action.status,
          items: action.payload
        };
      case GET_BYID_SUICIDE_FAILED:
        return {
          ...state,
          isLoading: true,
        };
      default:
        return state;
    };
  };

  export const getSuicideByPId = (state = intialData, action: any) => {

    switch (action.type) {
      case GET_BYPID_SUICIDE_STARTED:
        return {
          ...state,
          items: [],
          isLoading: true,
        };
      case GET_BYPID_SUICIDE_COMPLETED:
        return {
          ...state,
          isLoading: false,
          status: action.status,
          items: action.payload
        };
      case GET_BYPID_SUICIDE_FAILED:
        return {
          ...state,
          isLoading: true,
        };
      default:
        return state;
    };
  };

  export const getBySuicideRisk = (state = intialData, action: any) => {

    switch (action.type) {
      case GET_BYSUICIDERISK_STARTED:
        return {
          ...state,
          items: [],
          isLoading: true,
        };
      case GET_BYSUICIDERISK_COMPLETED:
        return {
          ...state,
          isLoading: false,
          status: action.status,
          items: action.payload
        };
      case GET_BYSUICIDERISK_FAILED:
        return {
          ...state,
          isLoading: true,
        };
      default:
        return state;
    };
  };

  export const deleteById = (state = intialData, action: any) => {

    switch (action.type) {
      case DELETE_BYID_SUICIDE_STARTED:
        return {
          ...state,
          items: [],
          isLoading: true,
        };
      case DELETE_BYID_SUICIDE_COMPLETED:
        return {
          ...state,
          isLoading: false,
          status: action.status,
          items: action.payload
        };
      case DELETE_BYID_SUICIDE_FAILED:
        return {
          ...state,
          isLoading: true,
        };
      default:
        return state;
    };
  };