export const GETALL_ASCENSION_STARTED = "GETALL_ASCENSION_STARTED";
export const GETALL_ASCENSION_COMPLETED = "GETALL_ASCENSION_COMPLETED";
export const GETALL_ASCENSION_FAILED = "GETALL_ASCENSION_FAILED";

export interface ILogin {
    status: {
      statusCode: number;
      statusDisplay: string;
      statusValue: boolean;
    };
    items: Array<any>;
    loginInput: {
      userId: string;
      username:string,
      password: string;
      organization:string;
      jwtToken: string;
      secretKey: string,
      email:string,
      otp:string,
      confirmNewPass: string,
      newPassword:string,
      securityQuestion: string;
      answer: string;
      roleFkId: {
        roleId: number;
        roleName: string;
        description: string;
        status: number;
        createdAt: Date;
        createdBy: string;
        updatedAt: Date;
        updatedBy: string;
      };
      status: number;
      createdAt: Date;
      createdBy: string;
      updatedAt: Date;
      updatedBy: string;
    };
    loginID: number;
    isLoading: boolean;
    error: string;
  }
  
  
  export const getAllAscensionFile = () => { 
    
    return {
      type: GETALL_ASCENSION_STARTED,
      payload: "status"
    };
  };