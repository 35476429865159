
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import WarningIcon from '../../assets/images/Recordicons/alert.png';
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


interface LogoutConfirmationModalProps {
    open: boolean;
    handleConfirm: () => void;
    handleClose: () => void;
    message: string;
}

const LogoutConfirmationModal: React.FC<LogoutConfirmationModalProps> = ({ open, handleConfirm, handleClose, message }) => {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiDialog-paper": { fontFamily: "Calibri", fontSize: "20px" } }}
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
                Mettler Health Care
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ color: "grey.500" }}
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                }}
            >
                <img
                    src={WarningIcon}
                    alt="Warning Icon"
                    style={{ width: '32px', height: '32px' }}
                />
                <Typography id="alert-dialog-description">{message}</Typography>
            </DialogContent>
            <DialogActions>

                <Button size="small" sx={{
                    backgroundColor: "#C9D1E2",
                    color: "black",
                    "&:hover": {
                        backgroundColor: "#cdd9e47a",
                    },
                }} onClick={handleClose}>
                    No
                </Button>
                <Button size="small" sx={{
                    backgroundColor: "#1F489F",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "#163B80",
                    },
                }} onClick={handleConfirm}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LogoutConfirmationModal;
