import { put, call } from "redux-saga/effects";
import {
    CREATE_NURSE_COMPLETED, CREATE_NURSE_FAILED,
    UPDATE_NURSE_COMPLETED, UPDATE_NURSE_FAILED,
    GETALL_NURSE_COMPLETED, GETALL_NURSE_FAILED,
    GETBYID_NURSE_COMPLETED, GETBYID_NURSE_FAILED,
    GETBYADMITTINGNURSE_NURSE_COMPLETED, GETBYADMITTINGNURSE_NURSE_FAILED,
    DELETEBYID_NURSE_COMPLETED, DELETEBYID_NURSE_FAILED,
    GENERATEPDF_NURSE_COMPLETED,GENERATEPDF_NURSE_FAILED
} from "../actions/NurseToNurse";
import { updateNurseAPI, createNurseAPI, getAllAPI, getByIdAPI, deleteByIdAPI, getByAdmittingNurseAPI,generatePdfAPI } from "../../utils/api/NurseToNurseApi";


export function* createNurseData(request: any) {
    try {
        const result = yield call(createNurseAPI.createNurse, request.input);
        yield put({
            type: CREATE_NURSE_COMPLETED,
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: CREATE_NURSE_FAILED, payload: e });
        return e;

    }
}

export function* updateNurseData(request: any) {
    try {
        const result = yield call(updateNurseAPI.updateById, request.input);
        yield put({
            type: UPDATE_NURSE_COMPLETED,
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: UPDATE_NURSE_FAILED, payload: e });
        return e;

    }
}

export function* getAllNurseData(request: any) {
    try {
        const result = yield call(getAllAPI.getAll, request.input);
        yield put({
            type: GETALL_NURSE_COMPLETED,
            payload: result.data
        });
    } catch (e) {
        yield put({ type: GETALL_NURSE_FAILED, payload: e });
        return e;

    }
}

export function* getByUsingId(request: any) {
    try {
        const result = yield call(getByIdAPI.getById, request.input);
        yield put({
            type: GETBYID_NURSE_COMPLETED,
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: GETBYID_NURSE_FAILED, payload: e });
        return e;

    }
}


export function* deleteByUsingId(request: any) {
    try {
        const result = yield call(deleteByIdAPI.deleteById, request.input);
        yield put({
            type: DELETEBYID_NURSE_COMPLETED,
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: DELETEBYID_NURSE_FAILED, payload: e });
        return e;

    }
}

export function* getByAdmittingNurse(request: any) {
    try {
        const result = yield call(getByAdmittingNurseAPI.getByAdmittingNurse, request.input);
        yield put({
            type: GETBYADMITTINGNURSE_NURSE_COMPLETED,
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: GETBYADMITTINGNURSE_NURSE_FAILED, payload: e });
        return e;

    }
}

export function* generatePdf(request: any) {
    try {
        const result = yield call(generatePdfAPI.generatePdf, request.input);
        yield put({
            type: GENERATEPDF_NURSE_COMPLETED,
            payload: result.data,
            input: request.input
        });
    } catch (e) {
        yield put({ type: GENERATEPDF_NURSE_FAILED, payload: e });
        return e;
    }
}