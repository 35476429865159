import {
    CREATE_NURSE_STARTED,
    CREATE_NURSE_COMPLETED,
    CREATE_NURSE_FAILED,
    UPDATE_NURSE_STARTED,
    UPDATE_NURSE_COMPLETED,
    UPDATE_NURSE_FAILED,
    GETALL_NURSE_STARTED,
    GETALL_NURSE_COMPLETED,
    GETALL_NURSE_FAILED,
    GETBYID_NURSE_STARTED,
    GETBYID_NURSE_COMPLETED,
    GETBYID_NURSE_FAILED,
    GETBYADMITTINGNURSE_NURSE_STARTED,
    GETBYADMITTINGNURSE_NURSE_COMPLETED,
    GETBYADMITTINGNURSE_NURSE_FAILED,
    DELETEBYID_NURSE_STARTED,
    DELETEBYID_NURSE_COMPLETED,
    DELETEBYID_NURSE_FAILED,
    GENERATEPDF_NURSE_STARTED,
    GENERATEPDF_NURSE_COMPLETED,
    GENERATEPDF_NURSE_FAILED
} from '../actions/NurseToNurse';

const intialLoginData = {
    status: {
        statusCode: 300,
        statusDisplay: "",
        statusValue: true
    },
    items: [],
    loginInput: {
        userId: "",
        password: "",
        username: "",
        jwtToken: "",
        secretKey: "",
        securityQuestion: "",
        email: "",
        otp: "",
        newPassword: "",
        confirmNewPass: "",
        answer: "",
        roleFkId: { roleId: 0, roleName: "", description: "", status: 0, createdAt: "", createdBy: "", updatedAt: "", updatedBy: "" },
        status: 0,
        createdAt: "",
        createdBy: "",
        updatedAt: "",
        updatedBy: ""

    },
    isLoading: true,
    isFormSubmit: false,
    isLoggedIn: false,
    loggedStatus: false,
    error: ''
}

export const createNurseData = (state = intialLoginData, action: any) => {
    switch (action.type) {

        case CREATE_NURSE_STARTED:
            return {
                ...state,
                isLoading: true,
                isFormSubmit: true,
                items: [],
                loginInput: action.input

            };

        case CREATE_NURSE_COMPLETED:
            return {
                ...state,
                isLoading: false,
                isFormSubmit: true,
                status: action.status,
                items: action.payload,
                loggedStatus: true
            };

        case CREATE_NURSE_FAILED:
            return {
                ...state,
                isLoading: true,
                loggedStatus: false
            };

        default:
            return state;
    }

};

export const updateNurseData = (state = intialLoginData, action: any) => {
    switch (action.type) {

        case UPDATE_NURSE_STARTED:
            return {
                ...state,
                isLoading: true,
                isFormSubmit: true,
                items: [],
                loginInput: action.input

            };

        case UPDATE_NURSE_COMPLETED:
            return {
                ...state,
                isLoading: false,
                isFormSubmit: true,
                status: action.status,
                items: action.payload,
                loggedStatus: true
            };

        case UPDATE_NURSE_FAILED:
            return {
                ...state,
                isLoading: true,
                loggedStatus: false
            };

        default:
            return state;
    }

};

export const getAllData = (state = intialLoginData, action: any) => {
    switch (action.type) {

        case GETALL_NURSE_STARTED:
            return {
                ...state,
                isLoading: true,
                isFormSubmit: true,
                items: [],
                loginInput: action.input

            };

        case GETALL_NURSE_COMPLETED:
            return {
                ...state,
                isLoading: false,
                isFormSubmit: true,
                status: action.status,
                items: action.payload,
                loggedStatus: true
            };

        case GETALL_NURSE_FAILED:
            return {
                ...state,
                isLoading: true,
                loggedStatus: false
            };

        default:
            return state;
    }

};

export const getByIdData = (state = intialLoginData, action: any) => {
    switch (action.type) {

        case GETBYID_NURSE_STARTED:
            return {
                ...state,
                isLoading: true,
                isFormSubmit: true,
                items: [],
                loginInput: action.input

            };

        case GETBYID_NURSE_COMPLETED:
            return {
                ...state,
                isLoading: false,
                isFormSubmit: true,
                status: action.status,
                items: action.payload,
                loggedStatus: true
            };

        case GETBYID_NURSE_FAILED:
            return {
                ...state,
                isLoading: true,
                loggedStatus: false
            };

        default:
            return state;
    }

};

export const getByAdmittingNurse = (state = intialLoginData, action: any) => {
    switch (action.type) {

        case GETBYADMITTINGNURSE_NURSE_STARTED:
            return {
                ...state,
                isLoading: true,
                isFormSubmit: true,
                items: [],
                loginInput: action.input

            };

        case GETBYADMITTINGNURSE_NURSE_COMPLETED:
            return {
                ...state,
                isLoading: false,
                isFormSubmit: true,
                status: action.status,
                items: action.payload,
                loggedStatus: true
            };

        case GETBYADMITTINGNURSE_NURSE_FAILED:
            return {
                ...state,
                isLoading: true,
                loggedStatus: false
            };

        default:
            return state;
    }
};

export const deleteUsingId = (state = intialLoginData, action: any) => {
    switch (action.type) {

        case DELETEBYID_NURSE_STARTED:
            return {
                ...state,
                isLoading: true,
                isFormSubmit: true,
                items: [],
                loginInput: action.input

            };

        case DELETEBYID_NURSE_COMPLETED:
            return {
                ...state,
                isLoading: false,
                isFormSubmit: true,
                status: action.status,
                items: action.payload,
                loggedStatus: true
            };

        case DELETEBYID_NURSE_FAILED:
            return {
                ...state,
                isLoading: true,
                loggedStatus: false
            };

        default:
            return state;
    }
};

export const generatePdf = (state = intialLoginData, action: any) => {
    switch (action.type) {

        case GENERATEPDF_NURSE_STARTED:
            return {
                ...state,
                isLoading: true,
                isFormSubmit: true,
                items: [],
                loginInput: action.input

            };

        case GENERATEPDF_NURSE_COMPLETED:
            return {
                ...state,
                isLoading: false,
                isFormSubmit: true,
                status: action.status,
                items: action.payload,
                loggedStatus: true
            };

        case GENERATEPDF_NURSE_FAILED:
            return {
                ...state,
                isLoading: true,
                loggedStatus: false
            };

        default:
            return state;
    }
};