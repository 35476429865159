import { put, call } from "redux-saga/effects";

import { GETALL_ASCENSION_COMPLETED,GETALL_ASCENSION_FAILED } from "../actions/AscensionFile";

import { AscensionFileAPI } from "../../utils/api/AscensionFileAPI"; 


export function* getAllAscensionFile() {
 
    try {  
         const result=yield call(AscensionFileAPI.getAllAscensionFile);        
        yield put({ 
            type: GETALL_ASCENSION_COMPLETED,     
            payload: result.data            
        });
    } catch (e) {
        yield put({ type: GETALL_ASCENSION_FAILED, payload: e });  
    return  e; 
    
    }
}



