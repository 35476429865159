import { HttpAddLogin } from "../Http";
export class AscensionFileAPI {
 
  public static getAllAscensionFile() { 
    var url = "/mettlerhealth/getAllFileUpload";      
 
  const resultMethod =   HttpAddLogin.axios().get(url, {
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*"
        }
      })
        .then(res => {     
          console.log(res)              
          return res;   
        })
        .catch((e: any) => {
          return e;               
        });
        return resultMethod;
  }
  

}

